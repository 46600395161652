import React from "react";
import Assessment_agree from "./Assessment-agree";
import Assessment_question from "./Assessment-question";



const Assessment = () => {


    return (
        <>
            <div className="maincontent">
                <div className="container">
                    {/* <Assessment_agree/> */}
                    <Assessment_question />
                </div>
            </div>
        </>
    )
}


export default Assessment;
