import React from "react";

const Assessment_agree = () => {

    return (
        <>
            <div className="contentwrap">
                <div className="contentbox">
                    <div className="contenthdr">
                        <h4>Terms & Conditions</h4>
                    </div>
                    <div className="contentbody">
                        <h5>Our Quiz fair practice policy.</h5>
                        <h6>Fair  Examination Practice </h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                            sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                            posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                            Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                            sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                            posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                            Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                            sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                            posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                            Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                            sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                            posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                            Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>
                        <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                            sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                            posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                            Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>

                    </div>
                </div>
                <div className="contentbtnwrap">
                    <a href="/assessment" className="sitebtn">I Agree</a>
                </div>
            </div>
        </>
    )
}


export default Assessment_agree;
