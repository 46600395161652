import React from "react";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import Login from "./Login";
import Welcome from "./components/Welcome";
import Knowhlfppt from "./components/Know-hlfppt";
import Assessment from "./components/Assessment";
import Assessment_score from "./components/Assessment-score";
import Profile from "./components/Profile";
import Upload_document from "./components/Upload-documents";
import Your_document from "./components/Your-documents";
 import MyComponent from "./components/My-components";

import Job_listing from "./job-portal/Job-listing";
import Job_details from "./job-portal/Job-details";
import Applynow from "./job-portal/Applynow";



function App() {

  return (
    <>


      <BrowserRouter>
        <MyComponent /> 
        {/* <Job_nav/> */}

        <Routes>

          <Route exact path='/login' element={<Login />} />
          <Route exact path='/' element={<Welcome />} />
          <Route exact path='/knowhlfppt' element={<Knowhlfppt />} />
          <Route exact path='/assessment' element={<Assessment />} />
          <Route exact path='/assessment-score' element={<Assessment_score />} />
          <Route exact path='/profile' element={<Profile />} />
          <Route exact path='/upload-documents' element={<Upload_document />} />
          <Route exact path='/your-document' element={<Your_document />} />

          {/* JOBS PAGES */}
          <Route exact path='/job-listing' element={<Job_listing />} />
          <Route exact path='/job-details' element={<Job_details />} />
          <Route exact path='/applynow' element={<Applynow />} />


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
