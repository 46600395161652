import React from "react";

const Knowhlfppt = () => {


    return (
        <>
            <div className="maincontent  animate__animated animate__fadeIn animate__slower">
                <div className="container">
                    <div className="contentwrap">
                        <div className="contentbox">
                            <div className="contenthdr">
                                <h4>All the best !!</h4>
                                <div className="timebox">
                                    <p className="statictime">10:00 min</p>
                                </div>
                            </div>
                            <div className="contentbody">
                                <h5>Read the following paragraph carefully and answers the questions accordingly.</h5>
                                <h6>About HLFPPT</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                                    sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                                    posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                                    Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                                    sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                                    posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                                    Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>
                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                                    sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                                    posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                                    Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>
                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                                    sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                                    posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                                    Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>
                                <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse mattis commodo fringilla. Ut nec quam vel erat interdum pretium. Aliquam non ipsum dolor. Aliquam eget orci
                                    sed magna varius semper sed nec ligula. Praesent lacinia non lectus a tincidunt. Nullam mattis metus vitae aliquet posuere. Vestibulum lacinia nunc eu leo scelerisque, eu
                                    posuere turpis bibendum. Nunc ultrices, lectus eget vulputate ornare, quam sem luctus turpis, nec aliquet mi nisi sit amet turpis.
                                    Integer id dignissim erat. Duis tortor purus, sollicitudin vitae lacinia non, imperdiet in est. Sed eu sem eu magna rhoncus porta eget sagittis arcu.</p>

                            </div>
                        </div> 
                        <div className="contentbtnwrap">
                            <a href="/assessment" className="sitebtn">Begin Assessment</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Knowhlfppt;
